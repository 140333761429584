import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperCore, { Mousewheel } from "swiper";
import {
  MainBackgroundVideo,
  MainBackgroundVideoWrapper,
  MainBannerDescription,
  MainBannerTitle,
  MainBannerWrapper,
  MainCardItem,
  MainCardItemImage,
  MainCardWrapper,
  MainWrapper,
} from "./Main.styles";
import { Link } from "react-router-dom";

import "swiper/css";
import { useEffect, useRef, useState } from "react";

const calcDigit = () => {
  return window.innerWidth / 18;
};
const calcResizeWindow = () => {
  return window.innerWidth;
};

const mainCardList = [
  { src: "/assets/image/main/main-card-01.png", path: "/world" },
  { src: "/assets/image/main/main-card-02.png", path: "/gallery" },
  { src: "/assets/image/main/main-card-03.png", path: "/video" },
  { src: "/assets/image/main/main-card-04.png", path: "#" },
  { src: "/assets/image/main/main-card-05.png", path: "#" },
];

const Main = () => {
  const [digit, setDigit] = useState(() => calcDigit());
  const [windowWidth, setWindowWidth] = useState(() => calcResizeWindow());
  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    const handleResizeWindow = () => {
      setDigit(() => calcDigit());
      setWindowWidth(() => calcResizeWindow());
    };
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <MainWrapper>
      <MainBackgroundVideoWrapper>
        <MainBackgroundVideo
          muted
          autoPlay
          playsInline
          loop
          src="https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/main-background-video.mp4"
          // src="public\assets\video\gallery\gallery-preview-video.mp4"
        />
      </MainBackgroundVideoWrapper>
      <MainBannerWrapper>
        <MainBannerTitle>
          ROA
          <br className="mobile-visible" />
          LAND
        </MainBannerTitle>
        <MainBannerDescription>
          sweet dreams are
          <br className="mobile-visible" /> made of bits
        </MainBannerDescription>
      </MainBannerWrapper>
      <MainCardWrapper>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          modules={[Mousewheel]}
          slidesOffsetBefore={windowWidth - digit}
          slidesOffsetAfter={digit}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          freeMode
          mousewheel
        >
          {mainCardList.map(({ src, path }) => {
            return (
              <SwiperSlide key={src}>
                <Link to={path}>
                  <MainCardItem>
                    <MainCardItemImage src={src} />
                  </MainCardItem>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </MainCardWrapper>
    </MainWrapper>
  );
};

export default Main;
