import Layout from "@/components/common/Layout";
import media from "@/theme/media";
import styled, { css } from "styled-components";

export const CardSectionContainer = styled.div`
  overflow: hidden;
`;
export const CardSectionWrapper = styled(Layout.Container)`
  padding-top: 200px;
  max-width: 70%;
  margin: 0 auto;

  ${media.mobile`
    ${css`
      padding-top: 100px;
    `}
  `}
`;

export const CardCountNumber = styled.h2`
  font-size: 100px;
  font-weight: 700;
  margin-bottom: 30px;

  ${({ theme }) => css`
    color: ${theme.color.white};
  `}

  ${media.mobile`
    ${css`
      font-size: 60px;
    `}
  `}
`;

export const CardSectionDivider = styled.hr`
  border: 0;

  ${({ theme }) => css`
    margin: 4px 0 26px;
    border-bottom: 1px solid ${theme.color.gray[600]};
  `}
`;

export const CardTagWrapper = styled.div`
  margin-bottom: 150px;
`;

export const CardTag = styled.p`
  font-size: 60px;
  font-weight: 200;

  ${({ theme }) => css`
    color: ${theme.color.gray[500]};
  `}

  ${media.mobile`
    ${css`
      font-size: 30px;
    `}
  `}
`;

export const CardListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 200px;
  margin-bottom: "50px";

  ${media.mobile`
    ${css`
      gap: 10px;
      padding-bottom: 100px;
    `}
  `}
`;

export const CardItem = styled.li`
  width: calc(50% - 10px);
  height: 0;
  padding-bottom: calc(117.5% / 2);
  border-radius: 20px;
  background: rgba(217, 217, 217, 1);
  margin-bottom: 5%;

  ${media.mobile`
    ${css`
      width: calc(50% - 5px);
      border-radius: 10px;
    `}
  `}
`;

export const CardItemImage = styled.img`
  // width: auto;
  // height: auto;

  width: "auto";
  height: auto;
  object-fit: cover;

  ${media.mobile`
    ${css`
      width: calc(100%);
      width: calc(100%);
      object-fit: cover;
    `}
  `}
`;
