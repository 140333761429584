import media from "@/theme/media";
import styled, { css } from "styled-components";

const Container = styled.div`
  padding: 0 16px;
`;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

const HeaderOffset = styled.div`
  padding-top: 102px;

  ${media.mobile`
    ${css`
      padding-top: 50px;
    `}
  `}
`;

const Layout = { Container, Wrapper, HeaderOffset };

export default Layout;
