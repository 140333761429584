import {
  CharacterDescription,
  CharacterSectionLogo,
  CharacterSectionLogoWrapper,
  CharacterSectionWrapper,
  CharacterSlideImage,
  CharacterSlideImageWrapper,
  CharacterSlideWrapper,
  CharacterContentWrapper,
  CharacterVideoItem,
  CharacterVideoItemThumbnail,
  CharacterVideoItemThumbnailWrapper,
  CharacterVideoItemTitle,
  CharacterVideoWrapper,
  CharacterGradient,
} from "./CharacterSection.styles";

import SwiperCore, { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import Icon from "@/components/common/Icon";
import { deviceSizes } from "@/theme/media";
import "../CharacterSection/fontStyle.css";

SwiperCore.use([Autoplay]);

const characterSlideList = [
  // {
  //   src: "/assets/image/gallery/gallery-character-ex01.png",
  // },
  // {
  //   src: "/assets/image/gallery/gallery-character-ex02.png",
  // },
  // {
  //   src: "/assets/image/gallery/gallery-character-ex03.png",
  // },
  {
    src: "/assets/image/gallery/gallery-character-01.png",
  },
  {
    src: "/assets/image/gallery/gallery-character-02.png",
  },
  {
    src: "/assets/image/gallery/gallery-character-07.png",
  },
  {
    src: "/assets/image/gallery/gallery-character-04.png",
  },
  {
    src: "/assets/image/gallery/gallery-character-05.png",
  },
];

const characterVideoList = [
  {
    src: "/assets/image/gallery/gallery-character-video-01.png",
    title: "TPH (Teaching Preschooler Helper)",
    path: "https://youtu.be/1vltKLxuq7A?si=YVWjQRpDv0FXFKwT",
    description: "흐아아악",
  },
  {
    src: "/assets/image/gallery/gallery-character-video-02.png",
    title: "text area",
    path: "https://youtu.be/2yA6eRoi8m0?si=D2SWczzTmPiyc9a8",
  },
  {
    src: "/assets/image/gallery/gallery-character-video-03.png",
    title: "text area",
    path: "https://youtu.be/1vltKLxuq7A?si=YVWjQRpDv0FXFKwT",
  },
  {
    src: "/assets/image/gallery/gallery-character-video-04.png",
    title: "text area",
    path: "https://youtu.be/2yA6eRoi8m0?si=D2SWczzTmPiyc9a8",
  },
];

const CharacterSection = () => {
  return (
    <CharacterSectionWrapper>
      <CharacterSectionLogoWrapper>
        <CharacterSectionLogo src="/assets/image/common/logo-title.png" />
      </CharacterSectionLogoWrapper>
      <CharacterSlideWrapper>
        <Swiper
          loopedSlides={3}
          slidesPerView={1}
          loop
          autoplay={{ delay: 1 }}
          speed={3000}
          noSwiping
          noSwipingClass="swiper-slide"
          breakpoints={{
            [deviceSizes.mobile]: {
              slidesPerView: 3,
            },
          }}
        >
          {characterSlideList.map(({ src }, index) => {
            return (
              <SwiperSlide key={index}>
                <CharacterSlideImageWrapper>
                  <CharacterSlideImage src={src} />
                </CharacterSlideImageWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </CharacterSlideWrapper>
      <CharacterContentWrapper>
        <CharacterVideoWrapper>
          {/* {characterVideoList.map(
            ({ src, title, path, description }, index) => {
              return (
                <CharacterVideoItem
                  className="character-video-item"
                  key={"character-video-" + src}
                  as={"a"}
                  href={path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CharacterVideoItemThumbnailWrapper>
                    <CharacterVideoItemThumbnail src={src} />
                  </CharacterVideoItemThumbnailWrapper>
                  <CharacterVideoItemTitle style={{ display: "inline" }}>
                    <Icon icon="Play" />
                    {title}
                    <div
                      style={{
                        display: "flex",
                        fontSize: "15px",
                        marginTop: "20px",
                      }}
                    >
                      {description}
                    </div>
                  </CharacterVideoItemTitle>
                </CharacterVideoItem>
              );
            }
          )} */}
        </CharacterVideoWrapper>
        <CharacterDescription>
          {/* <div>로봇이 인간의 감정을 느낀다면 그것은</div>
          <div>인간입니까, 로봇입니까</div> */}
          <div>If a Robot feels Human emotions, </div>
          <div>Is it HUMAN or ROBOT?</div>
        </CharacterDescription>
      </CharacterContentWrapper>
      <CharacterGradient />
    </CharacterSectionWrapper>
  );
};

export default CharacterSection;
