import styled, { css, keyframes } from "styled-components";
import Layout from "../../common/Layout";
import Typography from "@/components/common/Typography";
import media from "@/theme/media";
import MagicalStylishSansSerifDemo from "./Magical-Stylish-Sans-Serif-Demo.otf";
import OG from "./OG-르네상스-비밀.woff";

export const WorldDetailContainer = styled(Layout.HeaderOffset)`
  ${({ theme }) => css`
    background: black;
    min-height: 100vh;
  `}
`;

export const WorldDetailWrapper = styled(Layout.Container)``;

export const WorldDetailContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${media.mobile`
    ${css`
      padding: 150px 0;
    `}
  `}
`;
export const WorldDetailContent = styled.div`
  width: 100%;
  font-size: 0;
  height: fit-content;
  position: relative;
`;

export const WorldDetailContentVideo = styled.video`
  max-height: 100vh;
  width: 100%;
`;

export const WorldDetailPointArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 132px;
  height: 132px;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;

  transition: opacity 0.3s ease;

  &.hint {
    opacity: 1;
  }

  ${media.mobile`
  ${css`
    opacity: 1;
    width: 32px;
    height: 32px;
    left: 50%; // Center horizontally on mobile
    top: 50%; // Center vertically on mobile
    transform: translate(-50%, -50%); // Center the point
  `}
`}
`;

const pointDecoAni = keyframes`
  0% {
    width:0;
    height:0;
  }

  50% {
    width:42px;
    height:42px;
  }
  100% {
    width:0;
    height:0;
  }
`;

export const WorldDetailPoint = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  ${({ theme }) => css`
    background: ${theme.color.purple[600]};
    &::after {
      content: "";
      display: inline-block;
      width: 42px;
      height: 42px;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid ${theme.color.purple[600]};

      animation: ${pointDecoAni} 2s infinite ease-in-out alternate;
    }

    ${media.mobile`
      &::after {
        content: "";
        display: inline-block;
        width: 10px; /* 모바일 크기에 맞게 조정 */
        height: 10px; /* 모바일 크기에 맞게 조정 */
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid ${theme.color.purple[600]};
    
        animation: ${pointDecoAni} 2s infinite ease-in-out alternate;
      }
    `}
  `}
`;

export const WorldDetailPointDescription = styled(Typography).attrs({
  variant: "body3",
})`
  position: absolute;
  left: 100px;
  top: 0;
  width: max-content;
  max-width: 493px;
  padding: 16px;
  background: rgba(17, 17, 17, 0.7);
  font-family: "san";
  ${({ theme }) => css`
    color: ${theme.color.white};
    font-family: "san";
    src: url("./");
  `}
  pointer-events:none;
  visibility: hidden;
  opacity: 0;
  transform: translateY(8px);
  transition: all 0.24s ease;
  z-index: 5;

  .visible & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  ${media.mobile`
    ${css`
      max-width: 253px;
      left: 30px;
    `}
  `}
`;

export const WorldDetailPointDescription2 = styled(Typography).attrs({
  variant: "body3",
})`
  position: absolute;
  left: 100px;
  top: 0;
  width: max-content;
  max-width: 493px;
  padding: 16px;
  background: rgba(17, 17, 17, 0.7);
  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
  pointer-events:none;
  visibility: visible;
  opacity: 0;
  transform: translateY(8px);
  transition: all 0.24s ease;
  z-index: 5;

  .visible & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  ${media.mobile`
    ${css`
      max-width: 253px;
      left: 30px;
    `}
  `}
`;

export const WorldDetailAllPointViewWrapper = styled.div`
  position: absolute;
  right: 60px;
  bottom: 60px;
  z-index: 100;

  ${media.mobile`
    ${css`
      display: none;
    `}
  `}
`;

export const WorldDetailAllPoint = styled(WorldDetailPoint)``;

export const WorldDetailBackButtonContainer = styled.div`
  position: relative;
  z-index: 10;
  pointer-events: none;
`;
export const WorldDetailBackButtonWrapper = styled(Layout.Wrapper)`
  padding-top: 40px;
  pointer-events: none;

  & button {
    pointer-events: auto;
  }

  ${media.mobile`
    ${css`
      padding-top: 20px;
    `}
  `}
`;
