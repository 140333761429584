import DefaultTemplate from "@/templates/DefaultTemplate";
import Gallery from "@/components/Gallery";

const GalleryPage = () => {
  return (
    <DefaultTemplate>
      <Gallery />
    </DefaultTemplate>
  );
};

export default GalleryPage;
