import { Link, useNavigate } from "react-router-dom";
import {
  HeaderContainer,
  HeaderLogo,
  HeaderLogoWrapper,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuIcon,
  HeaderMenuItem,
  HeaderMenuWrapper,
  HeaderRight,
  HeaderWrapper,
  MobileMenu,
  MobileMenuCloseButton,
  MobileMenuHeader,
  MobileMenuList,
  MobileMenuWrapper,
} from "./Header.styles";
import { useState } from "react";
import classNames from "classnames";
import useMobile from "@/hooks/useMobile";
import Icon from "@/components/common/Icon";

const headerMenuList = [
  {
    label: "HOME",
    path: "/",
  },
  {
    label: "WORLD",
    path: "/world",
  },
  {
    label: "GALLERY",
    path: "/gallery",
  },
  {
    label: "VIDEO",
    path: "/video",
  },
];

type HeaderProps = {
  showHeaderMenu: boolean;
};

const Header = ({ showHeaderMenu }: HeaderProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { isMobile } = useMobile();

  const [isHoverLogo, setIsHoverLogo] = useState(false);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogoWrapper
          onPointerEnter={() => setIsHoverLogo(true)}
          onPointerLeave={() => setIsHoverLogo(false)}
        >
          <Link to={"/"}>
            {isHoverLogo ? (
              <Icon icon="House" />
            ) : (
              <HeaderLogo src="/assets/image/common/logo-symbol.png" />
            )}
          </Link>
        </HeaderLogoWrapper>
        {showHeaderMenu && (
          <HeaderRight>
            <HeaderMenuWrapper>
              {isMobile && (
                <HeaderMenuButton
                  onClick={() => {
                    setIsOpenMenu((prev) => !prev);
                  }}
                >
                  <HeaderMenuIcon />
                </HeaderMenuButton>
              )}
              {!isMobile ? (
                <HeaderMenu>
                  {headerMenuList.map(({ label, path }, index) => {
                    return (
                      <HeaderMenuItem key={label}>
                        <Link to={path}>{label}</Link>
                      </HeaderMenuItem>
                    );
                  })}
                </HeaderMenu>
              ) : (
                <>
                  {isOpenMenu && (
                    <MobileMenuModal
                      onClose={() => {
                        setIsOpenMenu(false);
                      }}
                    />
                  )}
                </>
              )}
            </HeaderMenuWrapper>
          </HeaderRight>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;

type MobileMenuModalProps = {
  onClose: () => void;
};
const MobileMenuModal = ({ onClose }: MobileMenuModalProps) => {
  const navigate = useNavigate();
  return (
    <MobileMenuWrapper>
      <MobileMenuHeader>
        <MobileMenuCloseButton
          onClick={() => {
            onClose();
          }}
        >
          <Icon icon="Close" />
        </MobileMenuCloseButton>
      </MobileMenuHeader>
      <MobileMenuList>
        {headerMenuList.map(({ label, path }) => {
          return (
            <MobileMenu
              key={label}
              onClick={() => {
                navigate(path);
                onClose();
              }}
            >
              {label}
            </MobileMenu>
          );
        })}
      </MobileMenuList>
    </MobileMenuWrapper>
  );
};
