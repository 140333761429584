import { useState } from "react";
import {
  WorldContainer,
  WorldListWrapper,
  WorldCard,
  WorldCardImage,
  WorldCardIndex,
  WorldCardVideo,
  WorldCardWrapper,
  WorldWrapper,
  WorldCardCommingSoon,
  WorldCardCommingSoonText,
} from "./World.styles";
import classNames from "classnames";
import useMobile from "@/hooks/useMobile";
import { useNavigate } from "react-router-dom";

type WorldCardListType = {
  id: number;
  type: "video" | "image";
  src: string;
  disabled?: boolean;
};
const worldCardList: WorldCardListType[] = [
  {
    id: 1,
    type: "video",
    src: "https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/001_Discovering+Fire.mp4",
  },
  {
    id: 2,
    type: "video",
    src: "https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/002_Obsession.mp4",
  },
  {
    id: 3,
    type: "image",
    src: "/assets/image/world/world-bg-03.png",
    disabled: true,
  },
];
const World = () => {
  const [activeId, setActiveId] = useState(1);

  const { isMobile } = useMobile();
  const navigate = useNavigate();
  return (
    <WorldContainer>
      <WorldWrapper>
        <WorldListWrapper>
          {worldCardList.map(({ id, type, src, disabled }) => {
            const displayId = String(id).padStart(2, "0");
            return (
              <WorldCardWrapper
                className={classNames({
                  active: activeId === id,
                  disabled,
                })}
                key={id}
                onMouseOver={() => {
                  if (disabled) return;
                  setActiveId(id);
                }}
                onClick={() => {
                  if (disabled) return;

                  const timeout = isMobile ? 400 : 0;

                  setTimeout(() => {
                    navigate(`/world/${id}`);
                  }, timeout);
                }}
              >
                <WorldCardIndex>{displayId}</WorldCardIndex>
                <WorldCard>
                  {type === "video" ? (
                    <WorldCardVideo autoPlay muted src={src} />
                  ) : (
                    <>
                      <WorldCardImage src={src} />
                      <WorldCardCommingSoon>
                        <WorldCardCommingSoonText>
                          C O M I N G S O O N
                        </WorldCardCommingSoonText>
                      </WorldCardCommingSoon>
                    </>
                  )}
                </WorldCard>
              </WorldCardWrapper>
            );
          })}
        </WorldListWrapper>
      </WorldWrapper>
    </WorldContainer>
  );
};

export default World;
