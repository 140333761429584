import { convertObjectToCss } from "@/utils/common/styles";
import styled, { css } from "styled-components";
import Typography from "../Typography";

const Button = styled(Typography).attrs({ variant: "button1", as: "button" })`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  gap: 10px;
  border-radius: 8px;
  ${({ theme }) => css`
    color: ${theme.color.gray[400]};
    border: 1px solid ${theme.color.gray[400]};

    & path {
      stroke: ${theme.color.gray[400]};
    }
    &:hover {
      background: rgba(204, 204, 204, 1);

      color: ${theme.color.white};
      border: 1px solid ${theme.color.white};

      & path {
        stroke: ${theme.color.white};
      }
    }
  `}
`;

export default Button;
