import WorldDetail from "@/components/World/Detail";
import DefaultTemplate from "@/templates/DefaultTemplate";

const WorldDetailPage = () => {
  return (
    <DefaultTemplate showFooter={false}>
      <WorldDetail />
    </DefaultTemplate>
  );
};

export default WorldDetailPage;
