import { useEffect, useRef, useState } from "react";
import BackButton from "../common/BackButton";
import {
  VideoWrapper,
  VideoDisplayWrapper,
  VideoDisplay,
  VideoControlWrapper,
  VideoControlDimmer,
  VideoControlPlayButton,
  VideoBackButtonContainer,
  VideoBackButtonWrapper,
} from "./Video.styles";

const Video = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoState, setVideoState] = useState<"play" | "stop">("stop");

  useEffect(() => {
    const onPlay = () => {
      setVideoState("play");
    };
    const onEnd = () => {
      setVideoState("stop");
    };
    videoRef.current?.addEventListener("play", onPlay);
    videoRef.current?.addEventListener("ended", onEnd);

    return () => {
      videoRef.current?.removeEventListener("play", onPlay);
      videoRef.current?.removeEventListener("ended", onEnd);
    };
  }, []);
  return (
    <VideoWrapper>
      <VideoDisplayWrapper>
        <VideoDisplay
          ref={videoRef}
          playsInline
          src="https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/main-background-video.mp4"
        />
        {videoState === "stop" && (
          <VideoControlWrapper>
            <VideoControlDimmer />
            <VideoControlPlayButton
              onClick={() => {
                videoRef.current?.play();
              }}
            >
              PLAY
            </VideoControlPlayButton>
          </VideoControlWrapper>
        )}
      </VideoDisplayWrapper>
      <VideoBackButtonContainer>
        <VideoBackButtonWrapper>
          <BackButton>Back to ROA</BackButton>
        </VideoBackButtonWrapper>
      </VideoBackButtonContainer>
    </VideoWrapper>
  );
};

export default Video;
