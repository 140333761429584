import { useEffect, useRef } from "react";
import {
  CardCountNumber,
  CardItem,
  CardItemImage,
  CardListWrapper,
  CardSectionContainer,
  CardSectionDivider,
  CardSectionWrapper,
  CardTag,
  CardTagWrapper,
} from "./CardSection.styles";
import image1 from "./7.gif";
import image2 from "./11.gif";
import image3 from "./58.png";
import image4 from "./138.png";
import image5 from "./236.png";
import image6 from "./2956.png";

const cardList = [image1, image2, image3, image4, image5, image6];
const CardSection = () => {
  const cardRefs = useRef<HTMLElement[]>([]);
  useEffect(() => {
    const windowHeight = window.innerHeight;
    const handleScroll = () => {
      cardRefs.current.forEach((cardEl, index) => {
        const { top, bottom, height } = cardEl.getBoundingClientRect();

        const upRotation = 40;
        const upPositionX = 200;
        let opacity = 0;
        let rotation = upRotation;
        let positionX = upPositionX;

        const cardShowTop = windowHeight - top;
        const cardShowBottom = windowHeight - bottom;

        if (cardShowTop > 0 && cardShowBottom < windowHeight) {
          opacity = 1;
          if (cardShowBottom < 0) {
            opacity = 1 - (height - cardShowTop) / height;
          }
        } else {
          opacity = 0;
        }

        rotation = upRotation - upRotation * opacity;
        positionX = upPositionX - 200 * opacity;

        if (index % 2 === 0) {
          rotation *= -1;
          positionX *= -1;
        }

        cardEl.style.opacity = String(opacity);
        cardEl.style.transform = `perspective(1200px) translateX(${positionX}px) rotateY(${rotation}deg)`;
      });
    };

    document.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <CardSectionContainer>
      <CardSectionWrapper>
        <CardCountNumber>1722</CardCountNumber>
        <CardSectionDivider />
        <CardTagWrapper>
          <CardTag>#가장_달콤했던_기억의_조각</CardTag>
        </CardTagWrapper>
        <CardListWrapper>
          {cardList.map((item, index) => {
            return (
              <CardItem
                key={"card-" + item}
                ref={(element) => {
                  if (!element) return;
                  cardRefs.current[index] = element;
                }}
              >
                <CardItemImage src={item} />
                {/* <img src={item} alt={"image" + item} /> */}
              </CardItem>
            );
          })}
        </CardListWrapper>
      </CardSectionWrapper>
    </CardSectionContainer>
  );
};

export default CardSection;
