import { Link } from "react-router-dom";
import {
  FooterContainer,
  FooterContentColumn,
  FooterContentNavLink,
  FooterContentNavWrapper,
  FooterContentTitle,
  FooterContentWrapper,
  FooterLogo,
  FooterLogoWrapper,
  FooterWrapper,
} from "./Footer.styles";

const footerContentList = [
  {
    title: "ABOUT US",
    list: [
      { label: "ROALAND Foundation", path: "https://www.roaland.foundation/" },
      { label: "ROALAND Platform", path: "https://roaland.in/" },
      {
        label: "Gitbook",
        path: "https://roaland-foundation-ltd.gitbook.io",
      },
      {
        label: "WHITE PAPER",
        path: "https://roaland-foundation-ltd.gitbook.io/docs/whitepaper/roaland-whitepaper-kor",
      },
      { label: "Opensea", path: "https://opensea.io/collection/the-roaz-v2" },
      // { label: "FAQ", path: "#" },
      {
        label: "EXPLORE(Solscan)",
        path: "https://solscan.io/token/DDECrzgPs4b9qHVvPTAqTQDK42bHzFr3Y5g6pNmq2xjg",
      },
    ],
  },
  {
    title: "BUY about ROALAND",
    list: [
      { label: "Magic Eden", path: "https://magiceden.io/marketplace/roaz_s1" },
      { label: "ROASTORE", path: "https://roaland.in/funding" },
    ],
  },
  {
    title: "LEGALS",
    list: [
      {
        label: "Legal Opinion",
        path: "https://roaland-foundation-ltd.gitbook.io/docs/legal/roaland-legal-opinion",
      },
      {
        label: "Audit",
        path: "https://roaland-foundation-ltd.gitbook.io/docs/audit/roaland-audit",
      },
      // { label: "LICENSE", path: "#" },
      // { label: "TREAMS & CONDITIONS", path: "#" },
      // { label: "PRIVACY POLICY", path: "#" },
    ],
  },
  {
    title: "Community",
    list: [
      { label: "Telegram-Announcemts", path: "https://t.me/ROALAND_Notice" },
      { label: "Telegram-Chat", path: "https://t.me/ROALAND_Official" },
      { label: "Discord", path: "https://discord.com/invite/NvbmjmU6dK" },
      { label: "Twitter", path: "https://twitter.com/SOLROALAND" },
      {
        label: "Instagram",
        path: "https://www.instagram.com/roaland_official/?utm_medium=copy_link",
      },
      {
        label: "Youtube",
        path: "https://www.youtube.com/channel/UC_3nEO-mDthYHfhyDepunjQ",
      },
      {
        label: "Medium Blog",
        path: "https://medium.com/projectroaland-official",
      },
    ],
  },
];
const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterContentWrapper>
          {footerContentList.map(({ title, list }) => {
            return (
              <FooterContentColumn key={title}>
                <FooterContentTitle>{title}</FooterContentTitle>
                <FooterContentNavWrapper>
                  {list.map(({ label, path }) => {
                    return (
                      <FooterContentNavLink
                        as={"a"}
                        href={path}
                        key={label}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {label}
                      </FooterContentNavLink>
                    );
                  })}
                </FooterContentNavWrapper>
              </FooterContentColumn>
            );
          })}
        </FooterContentWrapper>
        <FooterLogoWrapper>
          <Link to={"/"}>
            <FooterLogo src="/assets/image/common/logo-title.png" />
          </Link>
        </FooterLogoWrapper>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
