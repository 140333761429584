import Main from "@/components/Main";
import DefaultTemplate from "@/templates/DefaultTemplate";

const MainPage = () => {
  return (
    <DefaultTemplate showFooter={false} showHeaderMenu={false}>
      <Main />
    </DefaultTemplate>
  );
};
export default MainPage;
