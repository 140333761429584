import Layout from "@/components/common/Layout";
import Typography from "@/components/common/Typography";
import media from "@/theme/media";
import styled, { css } from "styled-components";

export const FooterContainer = styled(Layout.Container).attrs({
  as: "footer",
})`
  ${({ theme }) => css`
    background: ${theme.color.black};
  `}
`;

export const FooterWrapper = styled(Layout.Wrapper)`
  padding: 60px 0 106px;
  margin-top: 60px;
  ${({ theme }) => css`
    border-top: 1px solid ${theme.color.gray[700]};
  `}

  ${media.mobile`
    ${css`
      margin-top: 20px;
      padding: 20px 0 50px;
    `}
  `}
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  gap: 100px;
  margin-bottom: 100px;

  ${media.mobile`
    ${css`
      gap: 32px 0;
      flex-wrap: wrap;
      margin-bottom: 50px;
    `}
  `}
`;

export const FooterContentColumn = styled.div`
  &:last-child {
    margin-left: auto;
  }

  ${media.mobile`
    ${css`
      flex-basis: 50%;
      &:last-child {
        margin-left: unset;
      }
    `}
  `}
`;

export const FooterContentTitle = styled(Typography).attrs({
  variant: "body1",
})`
  ${({ theme }) => css`
    color: ${theme.color.white};
    margin-bottom: 20px;

    ${media.mobile`
      ${css`
        margin-bottom: 10px;
      `}
    `}
  `}
`;

export const FooterContentNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const FooterContentNavLink = styled(Typography).attrs({
  variant: "body2",
})`
  ${({ theme }) => css`
    color: ${theme.color.gray[500]};
  `}
`;

export const FooterLogoWrapper = styled.h1`
  width: 148px;

  ${media.mobile`
  ${css`
    width: 106px;
  `}
    `}
`;

export const FooterLogo = styled.img`
  width: 100%;
`;
