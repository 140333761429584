import media from "@/theme/media";
import styled, { DefaultTheme, css } from "styled-components";

type TypographyProps = {
  variant?: keyof DefaultTheme["typography"];
};

const Typography = styled.p<TypographyProps>`
  ${({ theme, variant = "body1" }) => css`
    ${theme.typography[variant].desktop}

    ${media.mobile`
      ${css`
        ${theme.typography[variant].mobile}
      `}
    `}
  `}
`;

export default Typography;
