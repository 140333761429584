import World from "@/components/World";
import DefaultTemplate from "@/templates/DefaultTemplate";

const WorldPage = () => {
  return (
    <DefaultTemplate showFooter={false}>
      <World />
    </DefaultTemplate>
  );
};

export default WorldPage;
