import media from "@/theme/media";
import { createGlobalStyle, css } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    line-height:1.4;
  }

  .mobile-visible {
    display:none;
  }

  ${media.mobile`
    ${css`
      .mobile-visible {
        display: block;
      }
    `}
  `}
`;

export default GlobalStyles;
