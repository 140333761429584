import styled, { css } from "styled-components";
import Button from "../common/Button";
import Layout from "../common/Layout";
import media from "@/theme/media";

export const VideoWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.black};
    min-height: 100vh;
  `}
`;

export const VideoDisplayWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

export const VideoControlWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const VideoControlDimmer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.8);
`;
export const VideoControlPlayButton = styled.button`
  position: relative;
  z-index: 1;
  font-family: "Saira Stencil One", cursive;
  font-size: 180px;
  font-weight: 400;
  ${({ theme }) => css`
    color: ${theme.color.gray[600]};
    &:hover {
      color: ${theme.color.white};
    }
  `}

  ${media.mobile`
    ${css`
      font-size: 50px;
    `}
  `}
`;
export const VideoDisplay = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

export const VideoBackButtonContainer = styled(Layout.Container)`
  position: relative;
  z-index: 10;
  pointer-events: none;
`;
export const VideoBackButtonWrapper = styled(Layout.Wrapper)`
  padding-top: 142px;
  pointer-events: none;

  & button {
    pointer-events: auto;
  }
  ${media.mobile`
    ${css`
      padding-top: 70px;
    `}
  `}
`;
