import Layout from "@/components/common/Layout";
import Typography from "@/components/common/Typography";
import media from "@/theme/media";
import styled, { css } from "styled-components";

export const CharacterSectionWrapper = styled.section`
  padding-bottom: 200px;

  ${media.mobile`
    ${css`
      padding-bottom: 100px;
      padding-top: 40px;
    `}
  `}
`;

export const CharacterSectionLogoWrapper = styled.h1`
  text-align: center;
  max-width: 448px;
  margin: 0 auto;
  position: relative;
  z-index: 5;

  ${media.mobile`
    ${css`
      max-width: 210px;
    `}
  `}
`;

export const CharacterSectionLogo = styled.img`
  width: 100%;
`;

export const CharacterSlideWrapper = styled.div`
  margin-bottom: 10px;
  font-size: 0;

  .swiper-wrapper {
    transition-timing-function: linear;
  }
  ${media.mobile`
    ${css`
      margin-bottom: 30px;
    `}
  `}
`;

export const CharacterSlideImageWrapper = styled.div`
  width: 100%;
`;
export const CharacterSlideImage = styled.img`
  width: 100%;
`;

export const CharacterContentWrapper = styled(Layout.Container)`
  max-width: 1060px;
  margin: 0 auto;
  position: relative;
  z-index: 5;

  overflow: hidden;

  ${media.mobile`
    ${css`
      max-width: unset;
      overflow-x: auto;
      padding-left: 0;
      padding-right: 0;
    `}
  `}
`;
export const CharacterVideoWrapper = styled.ul`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 80px;
  ${media.mobile`
    ${css`
      padding: 0 16px;
      justify-content: flex-start;
      overflow-x: auto;
      margin-bottom: 30px;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
  `}
`;

export const CharacterVideoItem = styled.li`
  width: 100%;
  cursor: pointer;

  ${media.mobile`
    ${css`
      min-width: 250px;
    `}
  `}
`;

export const CharacterVideoItemThumbnailWrapper = styled.div`
  width: 100%;
  height: 129px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(17, 17, 17, 0.7);
  }

  .character-video-item:hover & {
    &::after {
      display: none;
    }
  }
`;
export const CharacterVideoItemThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const CharacterVideoItemTitle = styled(Typography).attrs({
  varaint: "body3",
})`
  ${({ theme }) => css`
    color: ${theme.color.gray[700]};
    display: inline-flex;
    align-items: center;
    gap: 2px;

    & svg {
      width: 24px;
     color: ${theme.color.black};
     fill: ${theme.color.gray[700]};
      }
    }

    .character-video-item:hover & {
      color: ${theme.color.white};

      & svg {
        & path {
          fill: ${theme.color.white};
        }
      }
    }
  `}
`;

export const CharacterDescription = styled(Typography).attrs({
  variant: "body2",
})`
  ${({ theme }) => css`
    color: ${theme.color.white};
    text-align: center;

    font-size: 30px;
    ${media.mobile`
      ${css`
        padding: 0 16px;
        font-size: 20px;
      `}
    `}
  `}
`;

export const CharacterGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  /* background: linear-gradient(180deg, #111111 0%, rgba(17, 17, 17, 0) 100%); */
`;
