import styled, { css, keyframes } from "styled-components";
import media from "@/theme/media";

export const MainWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.black};
    min-height: 100vh;
    position: relative;
    max-width: 100%;
    overflow: hidden;
  `}
`;

export const MainBackgroundVideoWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

export const MainBackgroundVideo = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

export const MainBannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MainBannerTitle = styled.h1`
  word-break: break-all;
  font-family: "Saira Stencil One", cursive;
  font-size: 180px;
  font-weight: 400;
  letter-spacing: 72px;
  ${({ theme }) => css`
    color: ${theme.color.white};
  `}

  ${media.mobile`
    ${css`
      font-size: 60px;
      letter-spacing: 24px;
    `}
  `}
`;
export const MainBannerDescription = styled.p`
  word-break: break-all;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 27px;
  ${({ theme }) => css`
    color: ${theme.color.white};
  `}

  ${media.mobile`
    ${css`
      font-size: 16px;
      letter-spacing: 6.4px;
    `}
  `}
`;

export const MainCardWrapper = styled.div`
  position: absolute;
  left: 0%;
  top: 50%;
  width: 100vw;
  transform: translate(0%, -50%);
  z-index: 5;

  & .swiper-slide {
    width: 502px;
    height: 704px;
  }

  ${media.mobile`
  ${css`
    & .swiper-slide {
      width: 299px;
      height: 419px;
    }
  `}
  `}

  @media screen and (max-width:320px) {
    & .swiper-slide {
      width: 260px;
      height: 370px;
    }
  }
`;

export const MainCardItem = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.5);
    z-index: 5;
  }

  &:hover::after {
    display: none;
  }
`;

export const MainCardItemImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
