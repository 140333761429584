import { PropsWithChildren } from "react";
import { DefaultTemplateWrapper } from "./DefaultTemplate.styles";
import Header from "./Header";
import Footer from "./Footer";

type DefaultTemplateProps = {
  showFooter?: boolean;
  showHeaderMenu?: boolean;
};
const DefaultTemplate = ({
  children,
  showFooter = true,
  showHeaderMenu = true,
}: PropsWithChildren<DefaultTemplateProps>) => {
  return (
    <DefaultTemplateWrapper>
      <Header showHeaderMenu={showHeaderMenu} />
      <main>{children}</main>
      {showFooter && <Footer />}
    </DefaultTemplateWrapper>
  );
};

export default DefaultTemplate;
