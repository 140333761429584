import CharacterSection from "./CharacterSection";
import {
  GalleryWrapper,
  RoaLandTitle,
  RoaLandTitleContainer,
  RoaLandTitleWrapper,
} from "./Gallery.styles";
import CardSection from "./CardSection";
import VideoPreviewSection from "./VideoPreviewSection";

const Gallery = () => {
  return (
    <GalleryWrapper>
      <CharacterSection />
      <VideoPreviewSection />
      <CardSection />
      <RoaLandTitleContainer>
        <RoaLandTitleWrapper>
          <RoaLandTitle src="/assets/image/gallery/gallery-roaland-title.svg" />
        </RoaLandTitleWrapper>
      </RoaLandTitleContainer>
    </GalleryWrapper>
  );
};

export default Gallery;
