import { Routes, Route } from "react-router-dom";
import ResetStyles from "@/styles/ResetStyles";
import GlobalStyles from "./styles/GlobalStyles";

// Page
import WorldPage from "./pages/world";
import WorldDetailPage from "./pages/world/detail";
import GalleryPage from "./pages/gallery";
import MainPage from "./pages/main";
import VideoPage from "./pages/video";

function App() {
  return (
    <>
      <ResetStyles />
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/video" element={<VideoPage />} />
        <Route path="/world" element={<WorldPage />} />
        <Route path="/world/:id" element={<WorldDetailPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
      </Routes>
    </>
  );
}

export default App;
