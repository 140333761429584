import Icon from "@/components/common/Icon";
import Layout from "@/components/common/Layout";
import Typography from "@/components/common/Typography";
import media from "@/theme/media";
import styled, { css } from "styled-components";

export const HeaderContainer = styled(Layout.Container).attrs({ as: "header" })`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 102px;
  display: flex;
  align-items: center;

  ${media.mobile`
    ${css`
      height: 50px;
    `}
  `}
`;
export const HeaderWrapper = styled(Layout.Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderLogoWrapper = styled.h1`
  width: 44px;
  height: 44px;

  & svg {
    width: 44px;
    height: 44px;
  }

  ${media.mobile`
  ${css`
    width: 24px;
    height: 24px;
    & svg {
      width: 24px;
      height: 24px;
    }
  `}
  `}
`;

export const HeaderLogo = styled.img`
  width: 100%;
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 16px;
`;

export const HeaderMenuWrapper = styled.div`
  position: relative;
`;
export const HeaderMenuButton = styled.button`
  font-size: 0;
`;
export const HeaderMenuIcon = styled(Icon).attrs({ icon: "Menu" })`
  ${media.mobile`
    ${css`
      width: 24px;
      height: 24px;
    `}
  `}
`;

export const HeaderMenu = styled.ul`
  display: flex;
  gap: 44px;
`;

export const HeaderMenuItem = styled(Typography).attrs({
  variant: "button1",
  as: "li",
})`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  border-radius: 8px;
  pointer-events: auto;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
`;

export const MobileMenuWrapper = styled(Layout.Container)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  ${({ theme }) => css`
    background: ${theme.color.black};
  `}
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  margin-bottom: 20px;
`;

export const MobileMenuCloseButton = styled.button`
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
`;

export const MobileMenu = styled(Typography).attrs({
  variant: "button1",
  as: "li",
})`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.2);

  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
`;
