import Video from "@/components/Video";
import DefaultTemplate from "@/templates/DefaultTemplate";

const VideoPage = () => {
  return (
    <DefaultTemplate showFooter={false} showHeaderMenu={false}>
      <Video />
    </DefaultTemplate>
  );
};
export default VideoPage;
