import styled, { css } from "styled-components";
import Layout from "../common/Layout";

export const GalleryWrapper = styled(Layout.HeaderOffset)`
  ${({ theme }) => css`
    background: ${theme.color.black};
    min-height: 100vh;
  `}
`;

export const RoaLandTitleContainer = styled(Layout.Container)``;
export const RoaLandTitleWrapper = styled(Layout.Wrapper)``;
export const RoaLandTitle = styled.img`
  width: 100%;
`;
