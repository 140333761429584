import { PropsWithChildren } from "react";
import Button from "../Button";
import Icon from "../Icon";
import { useNavigate } from "react-router-dom";

const BackButton = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(-1);
      }}
    >
      <Icon icon="ChevronLeft" />
      {children}
    </Button>
  );
};

export default BackButton;
