import { CSSProp, css } from "styled-components";

export const deviceSizes = {
  mobile: 768,
};

type DeviceSizeType = keyof typeof deviceSizes;

type Media = {
  [key in keyof typeof deviceSizes]: (
    ...args: Parameters<typeof css>
  ) => CSSProp;
};

const media: Media = Object.keys(deviceSizes).reduce((acc, label) => {
  acc[label as keyof Media] = (...args: Parameters<typeof css>) => css`
    @media screen and (max-width: ${deviceSizes[label as DeviceSizeType]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {} as Media);

export default media;
