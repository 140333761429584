import { Link } from "react-router-dom";
import {
  VideoPreview,
  VideoPreviewSectionWrapper,
  VideoPreviewWrapper,
  VideoThumbnailItem,
  VideoThumbnailItemImage,
  VideoThumbnailWrapper,
} from "./VideoPreviewSection.styles";

const videoThumbnailList = [
  {
    src: "/assets/image/gallery/gallery-video-preview-thumbnail-01.png",
    path: "https://youtu.be/1vltKLxuq7A?si=YVWjQRpDv0FXFKwT",
  },
  {
    src: "/assets/image/gallery/gallery-video-preview-thumbnail-02.png",
    path: "https://youtu.be/2yA6eRoi8m0?si=D2SWczzTmPiyc9a8",
  },
  {
    src: "/assets/image/gallery/gallery-video-preview-thumbnail-03.png",
    path: "https://youtu.be/1vltKLxuq7A?si=YVWjQRpDv0FXFKwT",
  },
  {
    src: "/assets/image/gallery/gallery-video-preview-thumbnail-04.png",
    path: "https://youtu.be/2yA6eRoi8m0?si=D2SWczzTmPiyc9a8",
  },
];

const VideoPreviewSection = () => {
  const openNewPage = (path: any) => {
    // Open a new window or tab with the specified URL
    window.open(path, "_blank");
  };
  return (
    <VideoPreviewSectionWrapper>
      <VideoPreviewWrapper>
        <VideoPreview
          muted
          autoPlay
          loop
          src="https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/main-background-video.mp4"
        />
      </VideoPreviewWrapper>
      <VideoThumbnailWrapper>
        {videoThumbnailList.map(({ src, path }) => {
          return (
            <VideoThumbnailItem
              key={src}
              // onClick={() => openNewPage(path)}
              as={"a"}
              href={path}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VideoThumbnailItemImage src={src} />
            </VideoThumbnailItem>
          );
        })}
      </VideoThumbnailWrapper>
    </VideoPreviewSectionWrapper>
  );
};

export default VideoPreviewSection;
