import useMobile from "@/hooks/useMobile";
import {
  WorldDetailBackButtonContainer,
  WorldDetailBackButtonWrapper,
  WorldDetailContainer,
  WorldDetailContentVideo,
  WorldDetailContentContainer,
  WorldDetailWrapper,
  WorldDetailContent,
  WorldDetailPoint,
  WorldDetailPointArea,
  WorldDetailPointDescription,
  WorldDetailAllPointViewWrapper,
  WorldDetailAllPoint,
} from "./World.styles";
import BackButton from "@/components/common/BackButton";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import "../Detail/fontStyle.css";

// 해당 world 페이지의 id값에 맞게 world detail 내용을 수정해주세요.
const WORLD_POINT_LIST = [
  {
    id: 1,
    videoSrc:
      "https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/001_Discovering+Fire.mp4",
    items: [
      {
        id: 1,
        x: 26,
        y: 57,
        description: "December 2276.  \nImplementation of robot tax laws. ",
      },
      {
        id: 2,
        x: 38,
        y: 48,
        description:
          // "15세 이상의 국민은 \n1대의 개인용 \n로봇을 소유할 수 있다.",
          "Individuals aged 15 \nand above are allowed \nto own one personal robot.",
      },
      {
        id: 3,
        x: 50,
        y: 57,
        description:
          // "개인이 2대 이상의 \n로봇을 소유할 수 없다.",
          "Individuals are not permitted \nto own more than two robots.",
      },
      {
        id: 4,
        x: 65,
        y: 56,
        description:
          // "새로운 로봇을 구매할 경우, \n기존의 로봇은 국가에 \n반납해야 하며 \n이를 어기는 자는 \n벌금형부터 \n무기징역에 처한다.",
          "When purchasing a new robot, \nthe existing one must be \nreturned to the state.",
      },
      {
        id: 5,
        x: 47,
        y: 70,
        description:
          // "반납된 로봇은 \n어떠한 이유로든 \n재사용 될 수 없으며 \n반드시 지정된 곳에서 \n처리한다.",
          "The returned robot cannot \nbe reused for any reason \nand must be processed \nat designated locations. ",
      },
    ],
  },
  {
    id: 2,
    videoSrc:
      "https://nft-roa.s3.ap-northeast-2.amazonaws.com/public/002_Obsession.mp4",
    items: [
      {
        id: 1,
        x: 57,
        y: 50,
        description:
          // "저는 소각장의 유일한 \n인간 직원입니다.",
          "I am the only human employee \nat the incineration plant.",
      },
      {
        id: 2,
        x: 50,
        y: 52,
        description:
          // " ... 그들은 공포에 비틀거리면서 \n이곳에 옵니다.",
          " ... They come here \nstaggering with fear.",
      },
      {
        id: 3,
        x: 68,
        y: 55,
        description:
          // "저는 매일 죽음을 목격합니다.",
          "Every day, I witness death unfold.",
      },
      {
        id: 4,
        x: 30,
        y: 60,
        description:
          // "그들도 유언을 남깁니다.",
          "They leave behind their last words too.",
      },
      {
        id: 5,
        x: 40,
        y: 38,
        description:
          // "분명합니다. \n그러나 믿음은 개인의 몫이죠",
          "However, beliefs belong \nto each individual. ",
      },
    ],
  },
];

const WorldDetail = () => {
  const { id } = useParams<"id">();
  const { isMobile } = useMobile();

  const detailContentRef = useRef<HTMLDivElement>(null);

  const worldItem = WORLD_POINT_LIST.find((item) => item.id === Number(id));

  const videoRef = useRef<HTMLVideoElement | null>(null); // 타입에 null을 허용

  useEffect(() => {
    // videoRef가 null인 경우에 대한 체크
    if (!videoRef.current) return;

    // 동영상이 로드되면 재생 및 무한 반복 설정
    videoRef.current.addEventListener("loadeddata", () => {
      videoRef.current!.play(); // non-null assertion 사용
    });

    // 동영상이 끝났을 때 다시 재생하도록 설정
    videoRef.current.addEventListener("ended", () => {
      videoRef.current!.currentTime = 0; // non-null assertion 사용
      videoRef.current!.play(); // non-null assertion 사용
    });

    // 컴포넌트가 언마운트되면 이벤트 리스너 제거
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadeddata", () => {});
        videoRef.current.removeEventListener("ended", () => {});
      }
    };
  }, []);

  const findPointAreaEls = () => {
    if (!detailContentRef.current) return [];
    return detailContentRef.current.querySelectorAll(".point-area") || [];
  };

  useEffect(() => {
    findPointAreaEls()?.forEach((pointAreaEl) => {
      pointAreaEl.classList.add("hint");
    });
    setTimeout(() => {
      findPointAreaEls()?.forEach((pointAreaEl) => {
        if (pointAreaEl.classList.contains("visible")) {
          return;
        }
        pointAreaEl.classList.remove("hint");
      });
    }, 3000);
  }, []);

  return (
    <WorldDetailContainer>
      <WorldDetailWrapper>
        <WorldDetailContentContainer>
          <WorldDetailContent ref={detailContentRef}>
            <WorldDetailContentVideo
              ref={videoRef}
              muted
              autoPlay
              playsInline
              loop
              src={worldItem?.videoSrc}
            />
            {worldItem?.items.map(({ id, x, y, description }) => {
              const descriptionWithLineBreaks = description
                .split("\n")
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ));
              return (
                <WorldDetailPointArea
                  className="point-area"
                  onMouseOver={(event) => {
                    event.currentTarget.classList.add("hint");
                    console.log("ev id :", id);
                  }}
                  onMouseOut={(event) => {
                    event.currentTarget.classList.remove("hint");
                    event.currentTarget.classList.remove("visible");
                  }}
                  onClick={(event) => {
                    if (isMobile) {
                      event.currentTarget.classList.add("visible");
                    }
                    console.log("point area event: ", event);
                  }}
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)", // Center the point
                    left: `${x}%`,
                    top: `${y}%`,
                  }}
                  key={id}
                >
                  <WorldDetailPoint
                    onClick={(event) => {
                      if (isMobile) return;
                      const pointAreaEl =
                        event.currentTarget.closest(".point-area");
                      pointAreaEl?.classList.add("visible");
                    }}
                  />
                  <WorldDetailPointDescription
                    // style={{
                    //   fontFamily: "Arial, sans-serif",
                    //   fontStyle: "italic",
                    // }}
                    style={{
                      fontFamily: "IBMPlexMono-Text",
                      fontSize: "12px",
                    }}
                  >
                    {descriptionWithLineBreaks}
                  </WorldDetailPointDescription>
                </WorldDetailPointArea>
              );
            })}
          </WorldDetailContent>
        </WorldDetailContentContainer>
        <WorldDetailAllPointViewWrapper>
          <WorldDetailAllPoint
            onMouseOver={() => {
              findPointAreaEls()?.forEach((pointAreaEl) => {
                pointAreaEl.classList.add("hint");
              });
            }}
            onMouseOut={() => {
              setTimeout(() => {
                findPointAreaEls()?.forEach((pointAreaEl) => {
                  if (pointAreaEl.classList.contains("visible")) {
                    return;
                  }
                  pointAreaEl.classList.remove("hint");
                });
              }, 2400);
            }}
          />
        </WorldDetailAllPointViewWrapper>
        <WorldDetailBackButtonContainer>
          <WorldDetailBackButtonWrapper>
            <BackButton>Back to ROA</BackButton>
          </WorldDetailBackButtonWrapper>
        </WorldDetailBackButtonContainer>
      </WorldDetailWrapper>
    </WorldDetailContainer>
  );
};

export default WorldDetail;
