const fontWeight = {
  black: 900,
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 400,
  light: 300,
  extraLight: 200,
  thin: 100,
};

const GlobalTheme = {
  color: {
    gray: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#CCCCCC",
      500: "#AAAAAA",
      600: "#616161",
      700: "#424242",
      800: "#222222",
      900: "#111111",
    },
    white: "white",
    black: "rgba(17, 17, 17, 1)",
    purple: {
      500: "rgba(173, 39, 255, 1)",
      600: "rgb(102, 23, 230)",
    },
    blue: "blue",
    pink: "#F5A9E1",
  },
  font: {
    fontWeight: fontWeight,
  },
  typography: {
    body1: {
      desktop: {
        fontSize: "24px",
        fontWeight: fontWeight.semiBold,
      },
      mobile: {
        fontSize: "18px",
        fontWeight: fontWeight.semiBold,
      },
    },
    body2: {
      desktop: {
        fontSize: "21px",
        fontWeight: fontWeight.light,
      },
      mobile: {
        fontSize: "14px",
        fontWeight: fontWeight.light,
      },
    },
    body3: {
      desktop: {
        fontSize: "16px",
        fontWeight: fontWeight.regular,
        fontFamily: "Palace Script",
      },
      mobile: {
        fontSize: "12px",
        fontWeight: fontWeight.regular,
      },
    },
    button1: {
      desktop: {
        fontSize: "16px",
        fontWeight: fontWeight.regular,
      },
      mobile: {
        fontSize: "14px",
        fontWeight: fontWeight.regular,
      },
    },
  },
};

export default GlobalTheme;
