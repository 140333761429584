import styled, { css } from "styled-components";
import Typography from "@/components/common/Typography";
import Layout from "../common/Layout";
import media from "@/theme/media";



export const WorldContainer = styled(Layout.HeaderOffset)`
  ${({ theme }) => css`
    background: ${theme.color.black};
    min-height: 100vh;
  `}
`;

export const WorldWrapper = styled(Layout.Container)``;

export const WorldListWrapper = styled(Layout.Wrapper).attrs({ as: "ul" })`
  display: flex;
  padding: 60px 0 100px;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 102px);

  gap: 30px;

  ${media.mobile`
  ${css`
    padding: 40px 0 60px;
    display: block;
    gap: 25px;

    min-height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
  `}
  `}
`;

export const WorldCardWrapper = styled.li`
  width: 100%;
  flex-basis: 254px;
  height: calc(100vh - 262px);
  transition: all 0.12s linear;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &.active {
    flex-grow: 1;
  }

  @media screen and (max-width: 960px) {
    flex-basis: 200px;
  }

  ${media.mobile`
    ${css`
      overflow: hidden;
      will-change: all;

      height: 127px;
      transition: height 0.18s linear;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      &.active {
        height: calc(100vh - 50px - 254px - 150px);
      }
    `}
  `}
`;

export const WorldCardIndex = styled(Typography).attrs({
  variant: "body2",
})`
  margin-bottom: 20px;
  ${({ theme }) => css`
    color: ${theme.color.white};
    font-weight: ${theme.font.fontWeight.bold};

    .active & {
      color: ${theme.color.purple[500]};
    }
  `}
`;

export const WorldCard = styled.div`
  flex: 1;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: all 0.12s linear;
  font-size: 0;

  &:not(.active &) {
    filter: grayscale(100%);
  }
`;

export const WorldCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const WorldCardCommingSoon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;

  backdrop-filter: blur(14px);

  ${({ theme }) => css`
    color: ${theme.color.white};
  `}
`;
export const WorldCardCommingSoonText = styled(Typography).attrs({
  variant: "body2",
})`
  writing-mode: vertical-lr;
  text-orientation: upright;

  ${({ theme }) => css`
    color: ${theme.color.white};
  `}

  ${media.mobile`
    ${css`
      writing-mode: unset;
      text-orientation: unset;
    `}
  `}
`;

export const WorldCardVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
