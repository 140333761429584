import media from "@/theme/media";
import styled, { css } from "styled-components";

export const VideoPreviewSectionWrapper = styled.section`
  position: relative;
`;

export const VideoPreviewWrapper = styled.div`
  width: 100%;
  font-size: 0;
`;

export const VideoPreview = styled.video`
  width: 100%;
`;

export const VideoThumbnailWrapper = styled.ul`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1060px;
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;

  ${media.mobile`
    ${css`
      position: static;
      left: 0;
      top: 0;
      transform: unset;

      margin-top: 16px;
      padding: 0 16px;
      justify-content: flex-start;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
  `}
`;

export const VideoThumbnailItem = styled.li`
  width: 100%;
  height: 129px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 1);
    opacity: 0.7;
  }

  ${media.mobile`
    ${css`
      min-width: 250px;
    `}
  `}
`;

export const VideoThumbnailItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
